import { Analytics } from '@vercel/analytics/react';
import './App.css';

function App() {
  return (
    <div className="App">
      <p>
        Now go to Development
      </p>
      <Analytics />
    </div>
  );
}

export default App;